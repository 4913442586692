<template>
  <div class="home page">
    <div class="box">
      <!-- <div class="banner-img" /> -->
      <div class="header">
        <div class="search-tool">
          <router-link to="home" replace>
            <img class="logo" src="/logo.png" />
          </router-link>
          <router-link class="search-wrap" to="/h5/search">
            <!-- <div class="search-wrap" @click="$router.push('/h5/search')"> -->
            <img
              class="search-icon"
              src="@/assets/h5images/home/img_65757_0_6.png"
            />
            <span class="search-text">搜索族谱</span>
            <!-- </div> -->
          </router-link>
          <router-link to="user" replace>
            <img class="user" :src="userInfo.avatarImg" />
          </router-link>
        </div>
        <van-swipe class="banner-img" :autoplay="3000" indicator-color="white">
          <van-swipe-item
            v-for="item in banner"
            :key="item.id"
            class="banner-item"
          >
            <a v-if="item.url" :href="item.url">
              <img :src="item.image" alt="image" />
            </a>
            <img v-else :src="item.image" alt="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="middle-menu">
        <div
          class="menu-item"
          v-for="item in catList.slice(0, 5)"
          :key="item.id"
          @click="handleToCate(item.id)"
        >
          <img class="menu-icon" :src="item.image" />
          <span class="menu-label">{{ item.name }}</span>
        </div>
      </div>
      <div class="home-content">
        <div v-if="lectureList5" class="block">
          <div class="block-title">
            {{ lectureList5.name }}
            <span class="more" @click="handleToCate(lectureList5.id)"
              >查看更多</span
            >
          </div>
          <div v-loading="!lectureList5" class="block-content zixun">
            <div class="zixun-content">
              <div
                class="zixun-content-item"
                v-for="item in resultList[lectureList5.id]"
                :key="item.id"
                @click="$router.push(`/h5/lecture/${item.id}`)"
              >
                <span class="content">{{ item.title }}</span>
                <span class="date"
                  >[{{ $format(item.cdatetime, "YYYY-MM-DD") }}]</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="lectureList6" class="block">
          <div class="block-title">
            {{ lectureList6.name }}
            <span class="more" @click="handleToCate(lectureList6.id)"
              >查看更多</span
            >
          </div>
          <div class="block-content list-content">
            <div
              v-for="item in resultList[lectureList5.id]"
              :key="item.id"
              class="list-item"
              @click="$router.push(`/h5/lecture/${item.id}`)"
            >
              <img :src="item.image" alt="" class="thumb" />
              <div class="right-content">
                <div class="title">{{ item.title }}</div>
                <div class="desc">讲师：<span>曾子文化</span></div>
                <div class="desc">课数：<span>1节</span></div>
                <div class="desc">
                  观看次数：<span>{{ item.view_count }}人</span>
                </div>
                <div class="desc">
                  发布日期：<span>{{
                    $format(item.cdatetime, "YYYY-MM-DD")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import otherApis from "@/apis/other";
// import indexApis from "@/apis/index";
import { Swipe, SwipeItem } from "vant";
import { mapState } from "vuex";

Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  name: "Home",
  data() {
    return {
      banner: [],
      aboutImgSrc: "",
      data: [],
      activeIndex: 0,
      eventList: [],
      catList: [],
      lectureList5: null,
      lectureList6: null,
      resultList: {}
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.fetchBanner();
    this.fetchCate();
  },
  methods: {
    async fetchBanner() {
      console.log("fetch banner");
      const {
        data: { banners }
      } = await otherApis.banner();

      // const {
      //   data: { img }
      // } = await otherApis.articleAbout();
      this.banner = banners;
      // this.aboutImgSrc = img;
      // console.log(banners);
    },
    async fetchCate() {
      try {
        const {
          data: { course_cat }
        } = await otherApis.courseCategory();
        this.catList = course_cat;
        const list5 = course_cat.slice(0, 1);
        const list6 = course_cat.slice(1, 2);
        this.lectureList5 = list5 ? { ...list5[0] } : null;
        this.lectureList6 = list6 ? { ...list6[0] } : null;

        if (list5) {
          this.fetchList(list5[0].id);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchList(catId) {
      try {
        const {
          data: { course_list }
        } = await otherApis.courseIndex({
          page: 1,
          cat_id: catId
        });
        this.resultList[catId] = course_list;
        this.$forceUpdate();
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },

    handleChange(index) {
      this.activeIndex = index;
    },

    handleToCate(id) {
      if (!id) return;
      this.$router.push(`/h5/lecture?cat_id=${id}`);
      // this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 1125px;
  // height: 4990px;
  // padding-bottom: 100px;
  background-color: #ffffff;
}

.box {
  background-size: contain;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #f7f7f7;
  // background-image: url(./images/img_65757_1_0.png);
  // background: linear-gradient(90deg, #e15f23 0%, #d24b2b 100%);
  overflow: hidden;
}

.header {
  display: flex;
  // position: absolute;
  // top: 0;
  align-items: center;
  align-self: center;
  flex-direction: column;
  // background-color: #CA0A15;
  width: 1125px;
  height: 700px;
  background: linear-gradient(90deg, #e15f23 0%, #d24b2b 100%);
  overflow: hidden;
  padding-top: env(safe-area-inset-top, 0);
}

.search-tool {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  // margin-top: 23px;
  padding: 37px 0;
  width: 100%;
  z-index: 100;
  justify-content: center;
  // background-color: #fff;
}

.logo {
  margin-right: 39px;
  width: 125px;
  height: 120px;
}

.search-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 39px;
  border-radius: 50px;
  background-color: #fdffff;
  width: 750px;
  height: 100px;
  overflow: hidden;
}

.search-icon {
  margin-right: 31px;
  margin-left: 36px;
  width: 51px;
  height: 50px;
}

.search-text {
  margin-top: -2px;
  text-decoration: none;
  line-height: 36px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #666666;
  font-size: 44px;
}

.user {
  width: 90px;
  height: 90px;
}

.banner-img {
  // position: absolute;
  // left: 0;
  // top: 0;
  width: 1125px;
  height: 424px;

  .banner-item {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.middle-menu {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #ffffff;
  width: 1125px;
  height: 320px;
  overflow: hidden;
}

.menu-item {
  display: flex;
  align-items: center;
  flex-direction: column;

  .menu-icon {
    position: relative;
    // width: 133px;
    height: 120px;
    object-fit: contain;
  }

  .menu-label {
    position: relative;
    margin-top: 39px;
    text-decoration: none;
    line-height: 36px;
    letter-spacing: 0px;
    white-space: nowrap;
    color: #666666;
    font-size: 44px;
  }
}

.list-content {
  display: flex;
  flex-flow: column nowrap;
  // min-height: 100%;

  // overflow: auto;
  // height: calc(100% - 60px);
  // flex: none;

  .list-item {
    flex: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 1055px;
    height: 400px;
    background: #f7f7f7;
    border-radius: 10px;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;

    .thumb {
      height: 360px;
      width: 360px;
      flex: none;
      border-radius: 10px;
    }

    .right-content {
      margin-left: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }

    .title {
      font-size: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .desc {
      font-size: 36px;
      color: #333;
      overflow: hidden;
      // margin-top: 30px;

      span {
        color: #666;
      }
    }

    &:first-of-type {
      margin-top: 0px;
    }
  }
}

.home-content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: #ffffff;
  width: 1125px;
  overflow: hidden;

  .block {
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-top: 60px;
    padding-bottom: 60px;

    .block-title {
      text-align: left;
      color: #333;
      font-size: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .more {
        font-size: 36px;
        color: #21a3fe;
      }
    }

    .block-content {
    }
  }

  .zixun {
    .zixun-image {
      margin-top: 60px;
      width: 1065px;
      height: 600px;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;

      .zixun-item {
        height: 100%;
        width: 100%;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .zixun-title {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 90px;
          line-height: 90px;
          padding: 0 32px;
          box-sizing: border-box;
          color: #ffffff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 36px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .zixun-content {
      font-size: 36px;
      color: #333333;
      .zixun-content-item {
        margin-top: 40px;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          &::before {
            content: "‧";
            line-height: 32px;
            display: inline-block;
          }
        }

        .date {
          color: #8f8f8f;
          float: right;
        }
      }
    }
  }

  .dongtai {
    .dongtai-content {
      font-size: 36px;
      color: #333333;
      .dongtai-content-item {
        margin-top: 40px;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
          display: inline-block;
          width: calc(100% - 56px);
          overflow: hidden;
          text-overflow: ellipsis;

          &::before {
            content: "‧";
            line-height: 32px;
            display: inline-block;
          }
        }

        .date {
          color: #8f8f8f;
          float: right;
        }
      }
    }
  }

  .about {
    .about-image {
      position: relative;
      align-self: center;
      margin-top: 62px;
      border-radius: 10px;
      background-color: #fdbd9b;
      width: 1044px;
      height: 700px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .about-text {
      color: #333333;
      line-height: 72px;
      font-size: 44px;
      text-indent: 2em;
    }
  }

  .business {
    .business-item {
      display: flex;
      position: relative;
      align-items: center;
      align-self: center;
      flex-direction: row;
      justify-content: center;
      margin-top: 40px;
      background-color: #f7f7f7;
      width: 1044px;
      height: 360px;
      overflow: hidden;
      padding: 0 40px;
      box-sizing: border-box;

      &:first-of-type {
        margin-top: 65px;
      }

      .business-image {
        margin-right: 41px;
        border-radius: 10px;
        background-color: #937df7;
        width: 280px;
        height: 280px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .business-content {
        flex: 1;

        &-title {
          white-space: nowrap;
          color: #333333;
          font-size: 56px;
        }

        &-desc {
          color: #666666;
          font-size: 44px;
          margin-top: 52px;
          line-height: 60px;
          white-space: normal;
        }
      }
    }
  }

  .contact {
    margin-bottom: 40px;
    .contact-block {
      display: flex;
      position: relative;
      align-items: center;
      align-self: center;
      flex-direction: column;
      margin-top: 76px;
      background-color: #f7f7f7;
      width: 1044px;
      height: 260px;
      overflow: hidden;
      padding: 0 40px;
      box-sizing: border-box;

      .line {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 44px;

        .left-text {
          color: #333333;
        }

        .right-text {
          color: #666666;
        }
      }
    }
  }
}
</style>
